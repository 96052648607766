.navbar{
  margin-bottom:0px;
}

// .navbar-brand{
//   overflow: hidden;
//   margin:0px;
//   padding:3px 0px;
//   img{
//     max-width:100%;
//     max-height:100%;
//   }
// }

.navbar > .container .navbar-brand, .navbar > .container-fluid .navbar-brand {
    margin-left: 0px;
}

.navbar{
    margin-bottom:0px;
    transition: all 500ms ease-in-out;
    background-color: #000;
    background-color: rgba(0,0,0,1);
    border-color: #000;
    a{
        color:#666666;
    }
}

.navbar.normal {
    min-height:45px;
    @media only screen and (min-width : 768px){
        height: 100px;
    }
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    transition: 0.5s;
    background-color: #282828;
    background-color: rgba(30,30,30,1);
    .navbar-right {
        -webkit-transition: 0.5s;
        -moz-transition: 0.5s;
        -ms-transition: 0.5s;
        transition: 0.5s;
    }
    .navbar-brand {
        -webkit-transition: 0.5s;
        -moz-transition: 0.5s;
        -ms-transition: 0.5s;
        transition: 0.5s;
        padding: 5px 0 0 15px !important;
        @media only screen and (min-width : 768px){
            padding: 25px 0 0 15px !important;
        }
        @media only screen and (min-width : 992px){
          padding: 5px 0 0 15px !important;
        }
        img {
            max-height:35px;
            @media only screen and (min-width : 768px){
                max-height: 45px;
            }
            @media only screen and (min-width : 992px){
              max-height: 85px;
            }
            
            -webkit-transition: 0.5s;
            -moz-transition: 0.5s;
            -ms-transition: 0.5s;
            transition: 0.5s;
        }
    }
    .social-nav .nav#menu-header-navigation{
        @media only screen and (min-width : 768px){
          //padding-top:10px;
        }
    }
}


.navbar.shrink {
    @media only screen and (min-width : 768px){
        height: 45px;
    }
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    transition: 0.5s;
    background-color: #282828;
    background-color: rgba(30,30,30,0.9);
    .navbar-right {
        -webkit-transition: 0.5s;
        -moz-transition: 0.5s;
        -ms-transition: 0.5s;
        transition: 0.5s;
    }
    .navbar-brand{
        -webkit-transition: 0.5s;
        -moz-transition: 0.5s;
        -ms-transition: 0.5s;
        transition: 0.5s;
        padding: 5px 0 0 15px !important;
        img {
            max-height: 35px;
            -webkit-transition: 0.5s;
            -moz-transition: 0.5s;
            -ms-transition: 0.5s;
            transition: 0.5s;
        }
    }
    .nav-header{
      @media only screen and (min-width : 768px){
        padding-top:3px;
      }
    }
    .nav-primary{
      @media only screen and (min-width : 768px){
        padding-top:0px;
      }
    }
}

.navbar-default .navbar-nav>li>a, .navbar-default .navbar-text{
    color:#666;
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: #666;
}

.navbar-default .navbar-collapse{
    border-color:#ccc;
}

.navbar-default .prime-nav{
  @media only screen and (min-width : 768px){

  }
}

.navbar-default .social-nav{
    text-align:center;
    @media only screen and (min-width : 768px){
        text-align:left;
        float:right;
        min-height:45px;
        //border:1px solid #f00;
    }
    .nav#menu-header-navigation{
        display:inline-block;
        text-align:center;
        @media only screen and (min-width : 768px){
            display:block;
            text-align:left;
        }
    }
}
.navbar{
    a{transition: all 0.25s ease-in-out;}
    .nav-header{
        padding-top:10px;
        li>a{
            padding:0px;
            margin:0px;
        }
    }
    .nav-primary{
        padding-top:10px;
        li>a{
            padding-top:15px;
            padding-bottom:5px;
            padding-right:0px;
            padding-left:20px;
            text-transform: uppercase;
            font-size:1.0em;
            @media only screen and (min-width : 768px){
              padding-top:5px;
            }
            @media only screen and (min-width : 992px){
              font-size:1.2em;
            }
        }
    }
    .nav-right{
        margin-right:0px;
    }
    .navbar-nav.navbar-right:last-child {
        margin-right: 0px;
    }
    .navbar-nav>li>a{
        padding-left:0px;
        margin-left:10px;
        @media only screen and (min-width : 992px){
          //margin-left:20px;
        }
        border-bottom:2px solid $jumbo;
        background-color:transparent;
        color:$jumbo;
        &:hover, &:active{
            background:transparent;
            border-color:$silver;
            color:$silver;
        }
    }
    .navbar-nav>.active>a{
      background-color:transparent;
      color:$jumbo;
      border-bottom:2px solid $jumbo;
      &:hover, &:focus{
        background-color:transparent;
        color:$silver;
        border-bottom:2px solid $silver;
      }
    }
    // .navbar-nav>.active>a{
    //     background-color:transparent;
    //     color:rgb(123,155,96);
    //     border-color:rgb(123,155,96);
    //     &:hover, &:focus{
    //         background:transparent;
    //         color:inherit;
    //         border-color:rgb(244,110,48);
    //         color:rgb(244,110,48);
    //     }
    // }
    .nav#menu-header-navigation>li{
        float:left;
    }
    #menu-header-navigation>li>a{
        border-bottom:none;
        @media only screen and (min-width : 768px){
            margin-left:0px;
        }
        &:hover, &:focus{
            background:transparent;
        }
    }
    #menu-header-navigation>li>a[href*="facebook"]{
        &:hover{
            color:#3B5998!important;
        }
    }
    #menu-header-navigation>li>a[href*="twitter"]{
        &:hover{
            color:#00ACED!important;
        }
    }
    #menu-header-navigation>li>a[href*="linkedin"], .menu-linkedin a[href*="linkedin"]{
        &:hover{
            color:#007FB1!important;
        }
    }
    #menu-header-navigation>li>a[href*="vimeo"], .menu-vimeo a[href*="vimeo"]{
        &:hover{
            color:#44BBFF!important;
        }
    }
    #menu-header-navigation>li>a[href*="youtube"], .menu-vimeo a[href*="youtube"]{
        &:hover{
            color:#CD201F!important;
        }
    }
    #menu-header-navigation>li>a[href*="mailto"], .menu-vimeo a[href*="mailto"]{
        &:hover{
            color:#DC4E41!important;
        }
    }
    #menu-header-navigation>li>a[href*="google"], .menu-vimeo a[href*="google"]{
        &:hover{
            color:#DC4E41!important;
        }
    }
}
.navbar.normal{
    .nav-primary{
        clear:right;
    }
}
.navbar.shrink {
    .nav-primary{
        clear:none;
        @media only screen and (min-width : 768px){
          padding-right:10px;
        }
    }
    li>a{
      @media only screen and (min-width : 768px){
        //padding-top:10px;
      }
    }
}




/* Carousel/Slider styles */

.carousel-fade .carousel-inner .item {
  -webkit-transition-property: opacity;
  transition-property: opacity;
}
.carousel-fade .carousel-inner .item,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  opacity: 0;
}
.carousel-fade .carousel-inner .active,
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}
.carousel-fade .carousel-inner .next,
.carousel-fade .carousel-inner .prev,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.carousel-fade .carousel-control {
  z-index: 2;
}

/* multi image carousel */

.carousel-multi-image{
  .carousel-inner{
    > .item{
      
      transition: 500ms ease-in-out left;

    }
    .active{
      &.left{
        left:-25%;
      }
      &.right{
        left:25%;
      }
    }
    .next{
      left: 25%;
    }
    .prev{
      left: -25%;
    }
    @media all and (transform-3d), (-webkit-transform-3d) {
      > .item{
        // use your favourite prefixer here
        transition: 500ms ease-in-out all;
        backface-visibility: visible;
        transform: none!important;
      }
    }
  }
  .carouse-control{
    &.left, &.right{
      background-image: none;
    }
  }
}

/*
 *  Carousel Default Theme
 */
.carousel-theme-default .carousel-caption {
  left: 0%;
  right: 0%;
  padding: 5px 15px 25px;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  margin: 0px;
  color: #FFF;
  text-align: center;
  z-index: 10;
}
.carousel-theme-default .carousel-caption p {
  margin: 0px;
}
.carousel-theme-default .carousel-indicators {
  margin-bottom: 0px;
  bottom: 0px;
}
.carousel-theme-default .carousel-indicators li {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin: 0px;
  text-indent: -999px;
  border: 1px solid #FFF;
  border-radius: 10px;
  cursor: pointer;
  background-color: transparent;
}
.carousel-theme-default .carousel-indicators .active {
  margin: 0px;
  width: 12px;
  height: 12px;
  background-color: #FFF;
}
.carousel-theme-default .carousel-control {
  z-index: 12;
}
.carousel-theme-default .carousel-control.left {
  background-image: none;
}
.carousel-theme-default .carousel-control.right {
  background-image: none;
}
.carousel-theme-default .carousel-control .glyphicon-chevron-left,
.carousel-theme-default .carousel-control .carousel-control .icon-prev {
  left: 20%;
}
.carousel-theme-default .carousel-control .glyphicon-chevron-right,
.carousel-theme-default .carousel-control .carousel-control .icon-next {
  right: 20%;
}

.slide-title{
  font-size:1em;
  @media only screen and (min-width: 768px) {
    font-size: 1.6em;
  }
}