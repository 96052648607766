// .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
//     font-family: "Bree Serif",Bevan","Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
//     font-weight: 300;
//     line-height: 1.1;
//     color: inherit;
// }

body{
	font-family: 'Roboto', 'Helvetica Neue', serif;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    //font-family: 'Playfair Display SC', 'Times New Roman', serif;
   	font-family: 'Roboto Condensed', 'Helvetica Neue', serif;

	font-weight:700;
}

.main{
	padding-left:0px;
	padding-right:0px;
}

a:focus {
    outline: none;
    outline: 0px;
    outline-offset: 0px;
}

.txt-xs{
	font-size:0.6em;
}
.txt-sm{
	font-size: 0.8em;
}
.txt-md{
	font-size: 1.0em;
	@media only screen and (min-width: 768px) {
		font-size: 1.4em;
	}
}
.txt-lg{
	font-size: 1.2em;
	@media only screen and (min-width: 768px) {
		font-size: 1.6em;
	}
}
.txt-xl{
	font-size: 1.4em;
	@media only screen and (min-width: 768px) {
		font-size: 2.0em;
	}
}

.p-title{
	text-align:center;
	margin-bottom:30px;
}
.num-list ol {
    margin-left: 20px;
    padding-right: 0;
    list-style-type: none;
}

.num-list ol li {
    counter-increment: step-counter;
}
.num-list ol li::before {
	// content: counter(step-counter);
	// margin-left: -60px;
	// font-size: 24px;
	// background-color: #eadecb;
	// color: #b2553d;
	// font-weight: 700;
	// padding: 0px;
	// display: block;
	// border-radius: 22px;
	// height: 44px;
	// width: 44px;
	// text-align: center;
	// float: left;
	// clear: both;
	// border: 4px solid #b2553d;

	content: counter(step-counter);
	margin-left: -60px;
	font-size: 24px;
	background-color: #b2553d;
	color: #ffffff;
	font-weight: 700;
	padding: 0;
	display: block;
	border-radius: 22px;
	height: 44px;
	width: 44px;
	text-align: center;
	float: left;
	clear: both;
	border: 4px solid #000000;
}

.lead{
	color:#666;
	line-height:1.6em;
	font-weight:400;
}

.bg-snow{
	background-color: $snow;
}
.bg-silver{
	background-color: $silver;
}
.bg-iron{
	background-color: $iron;
}
.bg-jumbo{
	background-color: $jumbo;
}
.bg-steel{
	background-color: $steel;
}
.bg-oil{
	background-color: $oil;
}
.bg-black{
	background-color: $black;
}


.text-white{
	color: $white;
}
.text-snow{
	color: $snow;
}
.text-silver{
	color: $silver;
}
.text-iron{
	color: $iron;
}
.text-jumbo{
	color: $jumbo;
}
.text-steel{
	color: $steel;
}
.text-oil{
	color: $oil;
}
.text-shadow{
	text-shadow: 0px 1px 2px #000000, 0 0 5px #000000;
}
.events-col{
	margin-bottom:25px;
}
.event_item{
	line-height:1.6em;
	padding-bottom:15px;
	margin-bottom:15px;
	border-bottom:1px solid #eeeeee;
	.event_time{
		font-size:1.2em;
		text-transform: uppercase;
		letter-spacing: -1px;
		color:#666666;
	}
	.event_title{
		font-size:1.2em;
	}
}